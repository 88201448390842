<template>
  <div class="update-password common-style">
    <div class="title">修改密码</div>
    <el-form ref="updatePasswordForm" :rules="rules" :model="model" label-width="100px" size="small">
      <el-form-item label="账号：">
        <el-input v-model="model.userName" disabled/>
      </el-form-item>
      <el-form-item label="原密码：" prop="curPassword">
        <el-input :type="passwordVis ? '' : 'password'" v-model="model.curPassword"
                  placeholder="请输入原来的密码">
        </el-input>
        <el-button @click="passwordVis = !passwordVis" style="margin-left: 5px;" icon="el-icon-view"></el-button>
      </el-form-item>
      <el-form-item label="新密码：" prop="password">
        <el-input :type="passwordVis ? '' : 'password'" v-model="model.password"
                  placeholder="请输入新密码">
        </el-input>
      </el-form-item>
      <el-form-item label="确认新密码：" prop="checkPassword">
        <el-input :type="passwordVis ? '' : 'password'" v-model="model.checkPassword"
                  placeholder="请输入新密码">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round class="save-btn" @click="handleUpdatePassword">确定</el-button>
      </el-form-item>
    </el-form>
    <div class="go-back" @click="handleBack">
      <img src="../../../assets/img/mobile-personal-center/back.png"/>
    </div>
  </div>
</template>

<script>
  import {confirmPwd, updatePassword} from "@/api/personal-center";
  import {aesEncrypt} from '@/utils/encryption'
  import website from '@/const/website'
  import {mapState, mapActions} from 'vuex';


  export default {
    name: '',
    data() {
      let validatePass = (rule, value, callback) => {
        let reg = /^[A-Za-z\d]([A-Za-z\d._\-@]*?)[A-Za-z\d]$/
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if (value.length > 20 || value.length < 6) {
          callback(new Error('长度在 6 到 20 个字符'));
        } else if (!reg.test(value)) {
          callback(new Error('只允许字母、数字或者特殊字符（._-@），必须以数字或字母开头，不能以特殊字符结尾'));
        } else {
          if (this.model.checkPassword !== '') {
            this.$refs.updatePasswordForm.validateField('checkPassword');
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.model.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      }
      return {
        model: {},
        rules: {
          password: [
            {validator: validatePass, trigger: 'blur'}
          ],
          checkPassword: [
            {validator: validatePass2, trigger: 'blur'}
          ],
          curPassword: [
            {required: true, message: '请输入当前密码', trigger: 'change'}
          ]
        },
        passwordVis: false,
      }
    },
    computed: {
      ...mapState('personalCenter', ['personalObj']),
    },
    components: {},
    created() {
      let {userName} = this.personalObj;
      this.model.userName = userName;
    },
    methods: {
      ...mapActions(['LogOut']),
      handleUpdatePassword() {
        this.$refs.updatePasswordForm.validate((valid) => {
          if (!valid) {
            return;
          }
          this.doRealUpdatePassword();
        })
      },
      async doRealUpdatePassword() {
        let {curPassword, password} = this.model;
        let params = {
          confirmPwd: aesEncrypt(curPassword, website.encPassword)
        }
        let res = await confirmPwd(params);
        if (!res || !res.data) return;
        if (res.data.code != '0') return;

        let updateRes = await updatePassword({newPwd: aesEncrypt(password, website.encPassword)})
        if (!updateRes || !updateRes.data) return;
        if (updateRes.data.code != '0') return;
        this.$message.success('修改密码成功');
        await this.LogOut();
        this.$router.replace({path: "/"});
      },
      handleBack() {
        this.$emit("on-back")
      }
    }
  }
</script>

<style scoped lang="scss">
</style>