<template>
  <div class="personal-team-info">
    <div class="title">团队信息({{signUpList && signUpList.length || 0}})</div>

    <ul v-if="signUpList && signUpList.length">
      <li v-for="(item, index) in signUpList" :key="index">
        <div class="li-top">
          <div>
            <p class="p-name">队名：</p>
            <p>{{ item.groupName }}</p>
            <p class="p-name second">学校名称：</p>
            <p>{{ item.orgName }}</p>
          </div>
          <div>
            <p class="p-name">省份：</p>
            <p>{{ getProvince(item.province)}}</p>
            <p class="p-name second">学院/系：</p>
            <p>{{ item.college }}</p>
          </div>
          <div>
            <p class="p-name unique">专业名称：</p>
            <p>{{ item.major }}</p>
          </div>


        </div>
        <el-table border
                  :data="item.detailList"
                  :header-cell-style="{'background-color':'#F8FBFF'}"
                  style="width: 100%; margin-top: 10px">
          <el-table-column label="姓名" prop="name"/>
          <el-table-column label="手机号" prop="mobile"/>
          <el-table-column label="身份">
            <template slot-scope="{row}">
              <div v-if="row.role == 0">指导老师</div>
              <div v-if="row.role == 1">普通成员</div>
              <div v-if="row.role == 2">队长</div>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </ul>

    <div v-else class="no-data">
      <img src="../../../assets/img/mobile-personal-center/no-data.png"/>
      <template v-if="openRegistration">
        <p class="first-p">暂无报名信息，请点击</p>
        <p><span @click="handleSignUp">"我要报名"</span>进行报名参赛！</p>
      </template>
      <template v-else>
        <p class="first-p">暂无报名信息</p>
      </template>
    </div>

    <div class="to-study-platform" v-if="openToPlatform" @click="handleStudyPlatform"></div>

    <el-tooltip effect="dark" :content="tooltipContent" placement="top" v-else>
      <div class="to-study-platform" style="filter: grayscale(100%);"></div>
    </el-tooltip>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {dictByType} from "@/api/sign-up-management"

  export default {
    name: '',
    data() {
      return {
        startTime: window._CONFIG['TO_STUDY_START_TIME'],
        provinceOptions: []
      }
    },
    computed: {
      ...mapState('personalCenter', ['signUpList']),
      ...mapState(['openRegistration']),
      tooltipContent() {
        return `即将开放此功能，敬请期待`
      },
      // 是否开启跳转学习平台判断
      openToPlatform() {
        const {startTime} = this
        let timeStart = new Date(startTime).getTime()
        let currentTime = new Date().getTime()
        return currentTime > timeStart
      }
    },
    created() {
      this.dictByType();
    },
    components: {},
    methods: {
      async dictByType() {
        let res = await dictByType('geo_province_division');
        if (!res || !res.data) return;
        if (res.data.code != 0) return;
        this.provinceOptions = Object.assign([], res.data.data);
      },
      getProvince(province = '') {
        let obj = this.provinceOptions.find(val => {
          return val.value == province
        })

        if (obj) {
          return obj.label;
        } else {
          return '--'
        }
      },
      handleStudyPlatform() {
        // let url = `${window.location.origin}/training`;
        let url = window._CONFIG['TO_STUDY_URL']
        window.open(url, '_blank')
      },
      handleSignUp() {
        this.$router.push({name: 'mobile-sign-up-management'})
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal-team-info {
    .title {
      position: relative;
      font-size: 14px;
      color: #104175;
      font-weight: bold;
      margin-left: 20px;
      &::before {
        position: absolute;
        top: 4px;
        left: -10px;
        content: '';
        width: 2px;
        height: 12px;
        background: #104175;
      }
    }
    font-size: 14px;

    ul {
      list-style: none;
      .li-top {
        height: 140px;
        border-radius: 10px;
        background: #F5F9FF;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
        padding: 12px 10px;
        display: flex;
        justify-content: space-between;
        div {

          p {
            font-weight: 500;
            color: #222222;
            margin: 0;
          }

          .p-name {
            font-weight: normal;
            color: #4A4A4A;
            margin-bottom: 6px;
          }
          .second {
            margin-top: 17px;
          }
          .unique {
            margin-top: 62px;
          }
        }
      }

      li {
        margin-top: 10px;
      }
      li + li {
        margin-top: 20px;
      }
    }

    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #3D3D3D;
      margin-top: 10vh;
      img {
        width: 60%;
      }
      p {
        margin: 0;

        span {
          color: #0084FF;
        }
      }
      .first-p {
        margin-top: -10px;
        margin-bottom: 10px;
      }
    }

    .to-study-platform {
      width: 285px;
      height: 82px;
      background: url("../../../assets/img/mobile-personal-center/to-platform.png") no-repeat center;
      background-size: cover;
      margin: 10px auto;
    }
  }
</style>