import request from '@/plugins/axios';

// 报名
export function signUp(obj) {
  return request({
    url: `/crete/sign/up`,
    method: 'post',
    data: obj
  })

}

// 省份字典
export function dictByType(type) {
  return request({
    url: `/dict/type/${type}`,
    method: 'get'
  })
}


export function getOrgNumByName(query) {
  return request({
    url: `crete/sign/up/getOrgNumByName`,
    method: 'get',
    params: query
  })

}
