<template>
  <div class="mobile-personal-center">
    <personal-info @update-status="handleUpdateStatus"/>
    <div class="personal-content">
      <personal-team-info v-if="status == 0"/>
      <update-password v-if="status==1" @on-back="handleBack"/>
      <update-info v-if="status==2" @on-back="handleBack" @on-success="handleUpdateInfoSuccess"/>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import {getSignUpData} from '@/api/personal-center'
  import PersonalInfo from './components/personal-info'
  import PersonalTeamInfo from './components/personal-team-info'
  import UpdateInfo from './components/update-info'
  import UpdatePassword from './components/update-password'

  export default {
    name: '',
    data() {
      return {
        status: 0
      }
    },
    computed: {
      ...mapGetters([
        'userInfo' // 登录用户信息
      ]),
    },
    components: {
      PersonalInfo,
      PersonalTeamInfo,
      UpdateInfo,
      UpdatePassword,
    },
    created() {
      this.getSignUpData();
    },
    methods: {
      ...mapMutations("personalCenter", ['mtt_personalObj', 'mtt_signUpList']),
      ...mapActions(['LogOut']),
      async getSignUpData() {
        let id = this.userInfo.id || '';
        if (!id) return;
        let res = await getSignUpData(id);
        if (!res || !res.data) return
        if (res.data.code != 0) return;

        this.mtt_personalObj(Object.assign({}, res.data.data));
        let {signUpList = []} = res.data.data;
        this.mtt_signUpList(signUpList);
      },
      handleUpdateStatus(status = 0) {
        this.status = status
        this.getSignUpData();
      },
      handleBack() {
        this.status = 0;
      },
      handleUpdateInfoSuccess() {
        // this.$emit('on-success')
        this.status = 0;
      },
      handleUpdateStatus(status) {
        if (status === 0) {
          this.status = 1
        } else if (status === 1) {
          this.status = 2;
        } else if (status === 2) {
          this.doLogout();
        }
      },
      doLogout() {
        this.$confirm("确认退出登录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          await this.LogOut();
          this.$router.replace({path: "/"});
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .mobile-personal-center {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .personal-content {
      flex: 1;
      height: 1%;
      position: relative;
      /*border: 1px solid red;*/
      border-radius: 10px;
      background: #FFFFFF;
      margin-top: -20px;
      padding: 20px 20px 100px 20px;
      overflow-y: auto;
      z-index: 1;
    }

    .common-style {
      /deep/ .title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      /deep/
      .el-form {
        margin: 50px auto 0 auto;
      }
      /deep/ .el-input {
        height: 32px !important;
        border-radius: 18px;
      }
      /deep/ .el-input__inner {
        height: 32px !important;
        border-radius: 18px;
        line-height: 32px !important;
      }

      /deep/ .el-input__icon {
        line-height: 32px !important;
      }
      /deep/ .el-select {
        width: 100%;
      }

      /deep/ .go-back {
        position: absolute;
        top: 26px;
      }

      /deep/ .save-btn {
        min-width: 100%;
        height: 36px;
        border-radius: 20px;
        opacity: 1;
        background: linear-gradient(270deg, #05C0FF 0%, #005EFF 100%);
        color: #fff;
      }

      /deep/
      .el-form-item__content {
        display: flex;
      }
      /deep/
      .el-button {
        border-radius: 20px;
      }

    }
  }
</style>