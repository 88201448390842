<template>
  <div class="personal-info">
    <div class="info-top">
      <img class="avatar" src="../../../assets/img/mobile-personal-center/avatar.png"/>
      <div>
        <div class="about-name">
          <span class="name">{{personalObj.name || '--'}}</span>
          <img @click="handleSignUp" v-if="openRegistration" class="sign-up" src="../../../assets/img/mobile-personal-center/sign-up-btn.png"/>
        </div>
        <span>
            {{ personalObj.mobile || '--' }} | {{ personalObj.gender | filterSex}}
          </span>
      </div>
    </div>
    <div class="info-bottom">
      <span>身份证号:{{ personalObj.idCard || '--'}}</span>
      <span>邮箱:{{ personalObj.email || '--'}}</span>
    </div>

    <img class="set" src="../../../assets/img/mobile-personal-center/set.png" @click="handleSetClick">

    <ul class="set-content" v-if="showSet">
      <li @click="handleUpdateStatus(0)">
        <img src="../../../assets/img/mobile-personal-center/password.png"/>
        <span>修改密码</span>
      </li>
      <li @click="handleUpdateStatus(1)">
        <img src="../../../assets/img/mobile-personal-center/info.png"/>
        <span>修改信息</span>
      </li>
      <li @click="handleUpdateStatus(2)">
        <img src="../../../assets/img/mobile-personal-center/logout.png"/>
        <span>退出登录</span>
      </li>
      <div class="triangle-up"></div>
    </ul>


  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: '',
    data() {
      return {
        showSet: false
      }
    },
    computed: {
      ...mapState(['openRegistration']),
      ...mapState('personalCenter', ['personalObj']),
    },
    filters: {
      filterSex(val) {
        switch (val) {
          case 0:
            return '保密'
          case 1:
            return '男'
          case 2:
            return '女'
          default:
            return '未知'
        }
      }
    },
    components: {},
    methods: {
      handleSetClick() {
        this.showSet = !this.showSet;
      },
      handleUpdateStatus(status = 0) {
        this.$emit('update-status', status);
        this.showSet = false;
      },
      handleSignUp() {
        this.$router.replace({name: 'mobile-sign-up-management'})
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal-info {
    width: 100%;
    height: 200px;
    background: url("../../../assets/img/mobile-personal-center/top-bg.png") no-repeat center;
    background-size: cover;
    padding: 30px 2%;
    font-size: 14px;
    color: #fff;
    position: relative;
    .info-top {
      display: flex;
      width:80%;

      .about-name {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        .name {
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
        }
      }

    }

    .info-bottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    .set {
      position: absolute;
      right: 2%;
      top: 35px;
      width:30px;
    }

    .set-content {
      position: absolute;
      right: 3%;
      top: 75px;
      width: 100px;
      height: 110px;
      border-radius: 4px;
      z-index: 999;
      list-style: none;
      background: #FFFFFF;
      /*border: 1px solid red;*/
      color: #222222;
      li {
        margin: 12px;
        display: flex;
        align-items: center;
        img {
          margin-right: 2px;
        }
      }

      .triangle-up{
        position:absolute;
        top:-10px;
        right:5px;
        width:0;
        height:0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 14px solid #fff;
      }
    }

  }
</style>